import _constants2 from "../constants";
import _array2 from "./array";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

var _constants = _constants2;
var _array = _array2;

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

var filterNested = function filterNested(nodes) {
  var l = nodes.length;

  for (var i = 0; i < l; i += 1) {
    var _loop = function _loop(j) {
      if (i !== j) {
        if (nodes[i].contains(nodes[j])) {
          return {
            v: filterNested(nodes.filter(function (x) {
              return x !== nodes[j];
            }))
          };
        }
      }
    };

    for (var j = 0; j < l; j += 1) {
      var _ret = _loop(j);

      if ((typeof _ret === "undefined" ? "undefined" : _typeof(_ret)) === "object") return _ret.v;
    }
  }

  return nodes;
};

var getTopParent = function getTopParent(node) {
  return node.parentNode ? getTopParent(node.parentNode) : node;
};

var getAllAffectedNodes = function getAllAffectedNodes(node) {
  var nodes = (0, _array.asArray)(node);
  return nodes.filter(Boolean).reduce(function (acc, currentNode) {
    var group = currentNode.getAttribute(_constants.FOCUS_GROUP);
    acc.push.apply(acc, _toConsumableArray(group ? filterNested((0, _array.toArray)(getTopParent(currentNode).querySelectorAll("[" + _constants.FOCUS_GROUP + "=\"" + group + "\"]:not([" + _constants.FOCUS_DISABLED + "=\"disabled\"])"))) : [currentNode]));
    return acc;
  }, []);
};

exports.default = getAllAffectedNodes;
export default exports;