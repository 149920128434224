import _array2 from "./utils/array";
import _constants2 from "./constants";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _array = _array2;
var _constants = _constants2;

var focusIsHidden = function focusIsHidden() {
  return document && (0, _array.toArray)(document.querySelectorAll("[" + _constants.FOCUS_ALLOW + "]")).some(function (node) {
    return node.contains(document.activeElement);
  });
};

exports.default = focusIsHidden;
export default exports;