import _focusMerge3 from "./focusMerge";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.focusOn = undefined;
var _focusMerge = _focusMerge3;

var _focusMerge2 = _interopRequireDefault(_focusMerge);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var focusOn = exports.focusOn = function focusOn(target) {
  target.focus();

  if (target.contentWindow) {
    target.contentWindow.focus();
  }
};

var guardCount = 0;
var lockDisabled = false;

exports.default = function (topNode, lastNode) {
  var focusable = (0, _focusMerge2.default)(topNode, lastNode);

  if (lockDisabled) {
    return;
  }

  if (focusable) {
    if (guardCount > 2) {
      // eslint-disable-next-line no-console
      console.error("FocusLock: focus-fighting detected. Only one focus management system could be active. " + "See https://github.com/theKashey/focus-lock/#focus-fighting");
      lockDisabled = true;
      setTimeout(function () {
        lockDisabled = false;
      }, 1);
      return;
    }

    guardCount++;
    focusOn(focusable.node);
    guardCount--;
  }
};

export default exports;